<template>
  <div>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Produit {{ product.id }}</h3>
              &nbsp; &nbsp; <span> Publié le {{ date }}</span>
            </div>
            <div class="card-toolbar"></div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card shadow rounded">
                  <div style="height: 400px;">
                    <hooper group="group1" class="mb-3 h-100 rounded">
                      <slide
                        v-for="(slide, indx) in images"
                        :key="indx"
                        :index="indx"
                      >
                        <img :src="slide.url" class="w-100 h-100" />
                      </slide>
                    </hooper>
                  </div>

                  <hooper group="group1" :settings="hooperSettings">
                    <slide
                      v-for="(slide, indx) in images"
                      :key="indx"
                      :index="indx"
                    >
                      <img :src="slide.url" height="100%" />
                    </slide>

                    <hooper-navigation slot="hooper-addons"></hooper-navigation>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                    <hooper-progress slot="hooper-addons"></hooper-progress>
                  </hooper>
                </div>
                <div class="bg-light card shadow rounded">
                  <div class="card-body" v-show="documents">
                    <table class="table">
                      <tr
                        class="row"
                        v-for="(document, index) in documents"
                        :key="index"
                      >
                        <th class="col-sm-6">Document {{ index + 1 }}</th>
                        <td class="col-sm-6">
                          <a
                            href="#"
                            @click.prevent="downloadWithAxios(document)"
                            class="btn btn-link"
                            style="padding: 0;"
                            ><i class="fas fa-download"></i> télécharger</a
                          >
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="bg-light card shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-3 card-title">Info sur le produit</h4>
                  </div>
                  <div class="card-body">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ product.titre }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prix réel</th>
                        <td class="col-sm-6">{{ product.prix_reel }} fcfa</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prix offre</th>
                        <td class="col-sm-6">{{ product.prix_offre }} fcfa</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Pourcentage reduction</th>
                        <td class="col-sm-6">
                          {{ product.pourcentage_reduction }}%
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Quantiter stocker</th>
                        <td class="col-sm-6">{{ product.qte_stock }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="bg-light card shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-3 card-title">Info sur le créateur</h4>
                    <div
                      class="btn btn-success btn-xs"
                      v-if="userInfo"
                      @click="showInfoUser"
                    >
                      <i class="fas fa-minus text-white"></i>
                    </div>
                    <div
                      class="btn btn-bg-warning btn-xs"
                      v-else
                      @click="showInfoUser"
                    >
                      <i class="fas fa-plus text-white"></i>
                    </div>
                  </div>
                  <div class="card-body" v-if="userInfo">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ product.user.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Prenoms</th>
                        <td class="col-sm-6">{{ product.user.prenoms }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Email</th>
                        <td class="col-sm-6">{{ product.user.email }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Numéros de téléphone</th>
                        <td class="col-sm-6">{{ product.user.telephone_1 }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Role</th>
                        <td class="col-sm-6">{{ product.user.role }}</td>
                      </tr>
                    </table>

                    <div class="text-center">
                      <router-link
                        :to="{
                          name: 'user.show',
                          params: { id: product.user.id }
                        }"
                        class="btn btn-info"
                        >Voir plus</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="bg-light card shadow rounded">
                  <div class="card-header d-flex justify-content-between">
                    <h4 class="my-3 card-title">Info sur le partenaire</h4>
                    <div
                      class="btn btn-success btn-xs"
                      v-if="partnerInfo"
                      @click="showInfoPartner"
                    >
                      <i class="fas fa-minus text-white"></i>
                    </div>
                    <div
                      class="btn btn-bg-warning btn-xs"
                      v-else
                      @click="showInfoPartner"
                    >
                      <i class="fas fa-plus text-white"></i>
                    </div>
                  </div>
                  <div class="card-body" v-if="partnerInfo">
                    <table class="table">
                      <tr class="row">
                        <th class="col-sm-6">Nom</th>
                        <td class="col-sm-6">{{ product.partenaire.nom }}</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Adresse</th>
                        <td class="col-sm-6">
                          {{ product.partenaire.adresse }}
                        </td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Longitude</th>
                        <td class="col-sm-6">{{ product.partenaire.long }}°</td>
                      </tr>
                      <tr class="row">
                        <th class="col-sm-6">Latitude</th>
                        <td class="col-sm-6">{{ product.partenaire.lat }}°</td>
                      </tr>
                    </table>

                    <div class="text-center">
                      <router-link
                        :to="{
                          name: 'partner.show',
                          params: { id: product.partenaire.id }
                        }"
                        class="btn btn-info"
                        >Voir plus</router-link
                      >
                    </div>
                  </div>
                </div>
                <div class="card bg-light mt-5 shadow rounded">
                  <dl class="card-body">
                    <dt class="card-title"><h5>Description du produit</h5></dt>
                    <dd class="card-text">
                      <p>{{ product.description }}</p>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid mt-5">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="row">
          <div class="col-md-6">
            <div class="card shadow rounded">
              <div class="card-header">
                <h4 class="card-title" style="margin: 0;">
                  Tous les propositions du produits
                </h4>
              </div>
              <div class="card-body">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Demandeur</th>
                      <th>Prix</th>
                      <th>Date</th>
                      <th>Etats</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(proposition, index) in propositions"
                      :key="index"
                    >
                      <td>
                        {{ proposition.user.nom }}
                        {{ proposition.user.prenoms }}
                      </td>
                      <td>{{ proposition.proposition_prix }} Fcfa</td>
                      <td>{{ dateVisite(proposition.created_at) }}</td>
                      <td v-if="proposition.etat === 0">
                        <span class="badge badge-dark">{{ etats["0"] }}</span>
                      </td>
                      <td v-if="proposition.etat === 1">
                        <span class="badge badge-success">{{
                          etats["3"]
                        }}</span>
                      </td>
                      <td v-if="proposition.etat === 2">
                        <span class="badge badge-danger">{{ etats["2"] }}</span>
                      </td>
                      <td class="btn-group" v-if="proposition.etat === 0">
                        <button
                          class="btn btn-danger btn-sm"
                          @click="rejectProps(proposition)"
                        >
                          <i class="fas fa-times"></i>
                        </button>
                        <button
                          class="btn btn-success btn-sm"
                          @click="acceptProps(proposition)"
                        >
                          <i class="fas fa-check"></i>
                        </button>
                      </td>
                      <td v-else-if="proposition.etat === 2">
                        <button
                          class="btn btn-info btn-sm"
                          @click="infoRejcetProps(proposition)"
                        >
                          <i class="fas fa-eye"></i> Voir détails
                        </button>
                      </td>
                      <td v-else>
                        <span class="badge badge-light"
                          ><i class="fas fa-check-double text-success"></i>
                          Proposition accepter</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card shadow rounded">
              <div class="card-header">
                <h4 class="card-title" style="margin: 0;">
                  Tous les tags du produit
                </h4>
              </div>
              <div class="card-body">
                <div class="row justify-content-between">
                  <p class="col">
                    <span
                      class="badge badge-primary"
                      style="margin-right: 5px;margin-top: 5px;"
                      v-for="(tag, index) in tags"
                      :key="index"
                    >
                      #{{ tag.name }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import {
  Hooper,
  Navigation as HooperNavigation,
  Pagination as HooperPagination,
  Progress as HooperProgress,
  Slide
} from "hooper";
import axios from "axios";

export default {
  name: "Show",
  data() {
    return {
      initialize: "product/" + this.$route.params.id,
      product: {
        user: {},
        partenaire: {}
      },

      userInfo: false,
      partnerInfo: false,
      productInfo: false,
      otherDetails: false,
      etats: {
        0: "En Attente...",
        1: "Progammer",
        2: "Rejeter",
        3: "Terminer"
      },

      hooperSettings: {
        itemsToShow: 3,
        centerMode: true
      },

      tags: [],
      propositions: [],

      images: [],
      videos: [],
      documents: []
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    showInfoUser() {
      this.userInfo = !this.userInfo;
    },
    showInfoPartner() {
      this.partnerInfo = !this.partnerInfo;
    },
    showInfoProduct() {
      this.productInfo = !this.productInfo;
    },
    dateVisite(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    },
    timeVisite(date) {
      let d = new Date(date);
      let hours = d.getHours();
      let minutes = d.getMinutes();
      let secondes = d.getSeconds();

      minutes = minutes < 10 ? "0" + minutes : minutes;

      return [hours, minutes, secondes].join(":");
    },
    infoRejcetProps(propImmo) {
      this.info(propImmo.description);
    },
    reloadData() {
      let vm = this;
      axios.get(this.initialize).then(function(response) {
        vm.product = response.data.product;
        vm.images = response.data.product.images;
        vm.videos = response.data.product.videos;
        vm.documents = response.data.product.documents;
        vm.tags = response.data.product.tags;
        vm.propositions = response.data.propositions_products;
      });
    },
    rejectProps(propPartner) {
      this.reject(
        `product/proposal/${propPartner.id}/finish`,
        "proposition immobilière"
      );
    },
    acceptProps(propPartner) {
      this.accept(`product/proposal/${propPartner.id}/accept`, "Proposition");
    },
    reject(url, name) {
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment rejeter cette ${name} ?`,
        icon: "question",
        confirmButtonText: "Motifs"
      }).then(isConfirm => {
        if (isConfirm.isConfirmed) {
          Swal.fire({
            input: "textarea",
            inputLabel: "Message",
            inputPlaceholder: "Type your message here...",
            inputAttributes: {
              "aria-label": "Type your message here"
            },
            inputValidator: value => {
              if (!value) {
                return "Veillez saisir un motif svp!";
              }
            },
            showCancelButton: true
          }).then(isConfirm => {
            if (isConfirm.isDismissed) {
              Swal.fire({
                title: "Erreur",
                text: "Votre motif n'a été enregistrer !",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
                showClass: {
                  popup: "animate__animated animate__fadeInDown"
                },
                hideClass: {
                  popup: "animate__animated animate__fadeOutUp"
                }
              });
            }
            if (isConfirm.isConfirmed) {
              axios
                .put(url, { motif: isConfirm.value })
                .then(response => {
                  Swal.fire({
                    title: "Success",
                    text: response.data().message,
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  }).then(isConfirm => {
                    if (isConfirm) {
                      this.reloadData();
                    }
                  });
                })
                .catch(error => {
                  Swal.fire({
                    title: "Erreur",
                    text: error.response.data.message,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                    showClass: {
                      popup: "animate__animated animate__fadeInDown"
                    },
                    hideClass: {
                      popup: "animate__animated animate__fadeOutUp"
                    }
                  });
                });
            }
          });
        }
      });
    },
    accept(url, name) {
      let text = `accepter`;
      if (name === "Visite") text = "Terminer";
      Swal.fire({
        title: "Confirmation!",
        text: `Voulez vous vraiment ${text} cette ${name} ?`,
        icon: "question",
        confirmButtonText: "Ok"
      }).then(isConfirm => {
        if (isConfirm.isDismissed) {
          Swal.fire({
            title: "Erreur",
            text: "l'action n'a pas été effectuer !",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
            showClass: {
              popup: "animate__animated animate__fadeInDown"
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp"
            }
          });
        }
        if (isConfirm.isConfirmed) {
          let response = axios.get(url);
          if (response) {
            Swal.fire({
              title: `${name} ${text}!`,
              text: `${name} à bien été ${text} avec success`,
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
              showClass: {
                popup: "animate__animated animate__fadeInDown"
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp"
              }
            }).then(isConfirm => {
              if (isConfirm) {
                this.reloadData();
              }
            });
          }
        }
      });
    },
    info(data) {
      Swal.fire({
        title: "Motif du rejet",
        text: data,
        icon: "info",
        confirmButtonText: "Ok"
      });
    },
    downloadWithAxios(doc) {
      axios({
        url: `media/${doc.id}/download`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    }
  },
  computed: {
    date: function formatDate() {
      let d = new Date(this.product.created_at),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("-");
    }
  },
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    HooperPagination,
    HooperProgress
  }
};
</script>

<style scoped></style>
